import React from 'react'
import PropTypes from 'prop-types'
import NotesContext from "../notesContext";
import NoteButtonComponent from "./NoteButtonComponent";

class NoteTableComponent extends React.Component {
    constructor(props, context) {
        super(props, context);
        const notes = context.notes;
        this.state = {
            checked: Array(notes.length).fill(false)
        }
    }

    updateNoteState = (i, value) => {
        let checked = this.state.checked;
        checked[i] = value;
        this.props.onSelect(checked);
        return checked;
    }

    createTable = () => {
        const notes = this.context.notes;

        let table = [];
        for (let i = 0; i < notes.length; i = i + 12) {
            let children = []
            for (let j = i; j < i + 12; j++) {
                children.push(
                    <td>
                        <NoteButtonComponent
                            ordinal={j} checked={this.state.checked[j]}
                            onChange={(e) => this.setState({checked: this.updateNoteState(j, e)})}/>
                    </td>
                )
            }
            //Create the parent and add the children
            table.push(<tr>{children}</tr>)
        }
        return table
    }

    render() {
        return (
            <table>
                <tbody>
                {this.createTable()}
                </tbody>
            </table>
        )
    }
}

// NoteButtonComponent.propTypes = {
//     onSelect: PropTypes.func.isRequired
// }

NoteTableComponent.contextType = NotesContext;

export default NoteTableComponent;