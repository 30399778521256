import React from 'react'
import NoteComponent from "./NoteComponent";
import PropTypes from 'prop-types'

class HarpNoteComponent extends React.Component {
    // constructor({getShift, shiftMinus, shiftPlus}) {
    //     super({getShift, shiftMinus, shiftPlus});
    //     this.getShift = getShift;
    //     this.shiftMinus = shiftMinus;
    //     this.shiftPlus = shiftPlus;
    //}



    render() {
        return (
            <div className={this.props.effect}><NoteComponent ordinal={this.props.ordinal} shift={this.props.shift}/></div>
        )
    }
}

// PitchPickerComponent.contextType = NotesContext;

HarpNoteComponent.propTypes = {
    ordinal: PropTypes.number.isRequired,
    shift: PropTypes.number.isRequired,
    effect: PropTypes.oneOf(['blow', 'draw', 'draw-bend', 'blow-bend', 'overblow', 'overdraw'])
};

export default HarpNoteComponent