import React from 'react'
import NoteComponent from "./NoteComponent";
import HarpNoteComponent from "./HarpNoteComponent";
import PropTypes from "prop-types"

class HarpComponent extends React.Component {
    render() {
        return (
            <table>
                <tbody>
                <tr>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    {/*<td className="blow-bend"><NoteComponent ordinal={34} shift={this.props.shift}/></td>*/}
                    <td><HarpNoteComponent effect={"blow-bend"} ordinal={34} shift={this.props.shift}/></td>
                    <td/>
                </tr>
                <tr>
                    <td className="caption align-right overblow">overblow</td>
                    <td><HarpNoteComponent effect={"overblow"} ordinal={3} shift={this.props.shift}/></td>
                    <td/>
                    <td/>
                    <td><HarpNoteComponent effect={"overblow"} ordinal={15} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"overblow"} ordinal={18} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"overblow"} ordinal={22} shift={this.props.shift}/></td>
                    <td/>
                    <td><HarpNoteComponent effect={"blow-bend"} ordinal={27} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"blow-bend"} ordinal={30} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"blow-bend"} ordinal={35} shift={this.props.shift}/></td>
                    <td className="caption align-left blow-bend">blow bend</td>
                </tr>
                <tr>
                    <td className="caption align-right blow">blow</td>
                    <td><HarpNoteComponent effect={"blow"} ordinal={0} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"blow"} ordinal={4} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"blow"} ordinal={7} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"blow"} ordinal={12} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"blow"} ordinal={16} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"blow"} ordinal={19} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"blow"} ordinal={24} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"blow"} ordinal={28} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"blow"} ordinal={31} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"blow"} ordinal={36} shift={this.props.shift}/></td>
                    <td className="caption align-left blow">blow</td>
                </tr>
                <tr>
                    <td/>
                    <td>
                        <div className="note hole">1</div>
                    </td>
                    <td>
                        <div className="note hole">2</div>
                    </td>
                    <td>
                        <div className="note hole">3</div>
                    </td>
                    <td>
                        <div className="note hole">4</div>
                    </td>
                    <td>
                        <div className="note hole">5</div>
                    </td>
                    <td>
                        <div className="note hole">6</div>
                    </td>
                    <td>
                        <div className="note hole">7</div>
                    </td>
                    <td>
                        <div className="note hole">8</div>
                    </td>
                    <td>
                        <div className="note hole">9</div>
                    </td>
                    <td>
                        <div className="note hole">10</div>
                    </td>
                    <td/>
                </tr>
                <tr>
                    <td className="caption align-right draw">draw</td>
                    <td><HarpNoteComponent effect={"draw"} ordinal={2} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"draw"} ordinal={7} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"draw"} ordinal={11} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"draw"} ordinal={14} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"draw"} ordinal={17} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"draw"} ordinal={21} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"draw"} ordinal={23} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"draw"} ordinal={26} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"draw"} ordinal={29} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"draw"} ordinal={33} shift={this.props.shift}/></td>
                    <td className="caption align-left draw">draw</td>
                </tr>
                <tr>
                    <td className="caption align-right draw-bend">draw bend</td>
                    <td><HarpNoteComponent effect={"draw-bend"} ordinal={1} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"draw-bend"} ordinal={6} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"draw-bend"} ordinal={10} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"draw-bend"} ordinal={13} shift={this.props.shift}/></td>
                    <td/>
                    <td><HarpNoteComponent effect={"draw-bend"} ordinal={20} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"overdraw"} ordinal={25} shift={this.props.shift}/></td>
                    <td/>
                    <td><HarpNoteComponent effect={"overdraw"} ordinal={32} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"overdraw"} ordinal={37} shift={this.props.shift}/></td>
                    <td className="caption align-left overdraw">overdraw</td>
                </tr>
                <tr>
                    <td/>
                    <td/>
                    <td><HarpNoteComponent effect={"draw-bend"} ordinal={5} shift={this.props.shift}/></td>
                    <td><HarpNoteComponent effect={"draw-bend"} ordinal={9} shift={this.props.shift}/></td>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                </tr>
                <tr>
                    <td/>
                    <td/>
                    <td/>
                    <td><HarpNoteComponent effect={"draw-bend"} ordinal={8} shift={this.props.shift}/></td>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                </tr>
                </tbody>
            </table>
        )
    }
}

HarpComponent.propTypes = {
    shift: PropTypes.number.isRequired
};

export default HarpComponent