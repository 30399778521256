class Note {
    constructor(tone, octave, flatsharp) {
        this.tone = tone;
        this.octave = octave;
        this.flatsharp = flatsharp;
    }
    toString() {
        return this.tone + this.octave + this.flatsharp;
    }
}
export default Note