import React from 'react'
import NoteComponent from "./NoteComponent";
import PropTypes from 'prop-types'
import {Button} from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import NotesContext from "../notesContext";

class PitchPickerComponent extends React.Component {
    constructor({getShift, shiftMinus, shiftPlus}) {
        super();
        this.getShift = getShift;
        this.shiftMinus = shiftMinus;
        this.shiftPlus = shiftPlus;
    }

    render() {
        const {notes} = this.context;
        return (
            <table>
                <tbody>
                <tr>
                    <td>
                        <Button icon="pi pi-minus" className="p-button-sm"
                                disabled={this.getShift() <= 0}
                                onClick={() => {
                                    this.shiftMinus();
                                }}/>
                    </td>
                    <td>
                        <NoteComponent ordinal={this.getShift()} shift={0}/>
                    </td>
                    <td>
                        <Button icon="pi pi-plus" className="p-button-sm"
                                disabled={this.getShift() >= notes.length - 38}
                                onClick={() => {
                                    this.shiftPlus();
                                }}/>
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }
}

PitchPickerComponent.contextType = NotesContext;

PitchPickerComponent.propTypes = {
    getShift: PropTypes.func.isRequired,
    shiftMinus: PropTypes.func.isRequired,
    shiftPlus: PropTypes.func.isRequired
};

export default PitchPickerComponent