import React from 'react'
import PropTypes from 'prop-types'
import NoteComponent from "./NoteComponent";

class NoteButtonComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: props.checked
        }
        this.checked = props.checked;
    }

    onToggle() {
        console.log(this.state.checked);
        this.checked = !this.checked;
        this.setState({checked: this.checked});
        console.log(this.state.checked);
        this.props.onChange(this.checked);
    }

    render() {
        return (
            <button onClick={() => this.onToggle()}
                    className={"note-toggle" + (this.state.checked ? " note-toggle-checked" : "")}>
                <NoteComponent ordinal={this.props.ordinal} shift={0}/>
            </button>
        );
    }
}

NoteButtonComponent.propTypes = {
    ordinal: PropTypes.number.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};

export default NoteButtonComponent