import React, {Component} from 'react'
import ReactDOM from 'react-dom';
import HarpComponent from "./harp/HarpComponent";
import PitchPickerComponent from './harp/PitchPickerConponent'
import NoteTableComponent from "./harp/NoteTableComponent";
import NoteButtonComponent from "./harp/NoteButtonComponent";
import {TabView, TabPanel} from 'primereact/tabview';
import {Accordion, AccordionTab} from 'primereact/accordion';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {RICHTER_HARP_NOTES, RICHTER_HARP_MIN_SHIFT, RICHTER_HARP_MAX_SHIFT, notation} from "./RichterHarp";
import {NOTES} from "./notesContext";
import NoteComponent from "./harp/NoteComponent";


function App() {
    let [shift, setShift] = React.useState(0);
    let [harps, setHarps] = React.useState(new Map());

    function changeShift(value) {
        shift += value;
        setShift(shift);
    }

    function getShift() {
        return shift;
    }

    function onSelect(checked) {
        let selectedNotes = [];
        //console.log(checked);
        selectedNotes = checked.map((item, index) => {
            if (item) {
                return index;
            }
        }).filter((item) => {
            return item !== undefined;
        });
        //console.log(selectedNotes);
        if (selectedNotes.length === 0) {
            return;
        }
        let difficultyMap = new Map();
        for (let shift = RICHTER_HARP_MIN_SHIFT; shift <= RICHTER_HARP_MAX_SHIFT; shift++) {
            //console.log("Harp", NOTES[shift].toString());
            if (selectedNotes[0] < shift || selectedNotes[selectedNotes.length - 1] > shift + 37) {
                continue;
            }
            let difficulty = 0;
            let selectedHarpNotes = [];
            RICHTER_HARP_NOTES.forEach((item, index) => {
                selectedNotes.forEach((value) => {
                    let currentSelectedNoteShift = value - shift;
                    if (currentSelectedNoteShift === index) {
                        difficulty += item.difficulty;
                        selectedHarpNotes.push(item);
                        //console.log(item, index);
                    }
                })

            });
            let difficultyMapKey = {shift: shift, selectedHarpNotes: selectedHarpNotes};
            // let r = ""
            // for (let i in difficultyMapKey) {
            //     if (difficultyMapKey.hasOwnProperty(i)) {
            //         r += "obje" + "." + i + " = " + difficultyMapKey[i] + "\n";
            //     }
            // }


            difficultyMap.set(difficultyMapKey, difficulty);
            //console.log("difficulty", difficulty, selectedNotes[0], NOTES[shift].toString());

        }
        console.log("difficultyMap", difficultyMap);
        difficultyMap = new Map([...difficultyMap.entries()].sort((a, b) => {
            return a[1] - b[1]
        }));
        console.log("difficultyMap1", difficultyMap);
        let result = new Map();
        [...difficultyMap.entries()].forEach((currentItem, index) => {
            if (index <= 4) {
                result.set(currentItem[0].shift, currentItem[0].selectedHarpNotes);
            }
        });
        setHarps(result);
        console.log("result", result);
    }

    function createHarpList() {
        let items = [];
        harps.forEach((value, key) => {
            let header = (
                <React.Fragment><span><NoteComponent ordinal={key} shift={0} /></span><span>{notation(value)}</span></React.Fragment>
            )
            items.push(
                <AccordionTab header={header}>
                    <HarpComponent shift={key}/>
                </AccordionTab>
            );
        })
        return (
            <Accordion multiple>
                {items}
            </Accordion>
        )
    }

    return (
        <div className='wrapper'>
            <h1>SmartHarper</h1>
            {/*<TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>*/}
            <TabView>
                <TabPanel header="Header I">
                    <PitchPickerComponent getShift={() => getShift()}
                                          shiftMinus={() => {
                                              changeShift(-1)
                                          }}
                                          shiftPlus={() => {
                                              changeShift(1)
                                          }}/>
                    <HarpComponent ordinal={12} shift={shift}/>
                </TabPanel>
                <TabPanel header="Header II">
                    <NoteTableComponent onSelect={
                        (checked) => {
                            onSelect(checked)
                        }
                    }/>
                    {createHarpList()}
                </TabPanel>
                {/*<TabPanel header="Header III">*/}
                {/*    <NoteButtonComponent ordinal={0} checked={true}/>*/}
                {/*</TabPanel>*/}
            </TabView>

        </div>
    );
}

export default App;
