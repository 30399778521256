const DEFAULT_DIFFICULTIES = new Map();
DEFAULT_DIFFICULTIES.set('blow', 0);
DEFAULT_DIFFICULTIES.set('draw', 0);
DEFAULT_DIFFICULTIES.set('draw-bend', 10);
DEFAULT_DIFFICULTIES.set('blow-bend', 15);
DEFAULT_DIFFICULTIES.set('overblow', 25);
DEFAULT_DIFFICULTIES.set('overdraw', 50);

class RichterHarpNote {
    constructor(ordinal, effect, hole, notation, difficulty) {
        this.ordinal = ordinal;
        this.effect = effect;
        this.hole = hole;
        this.notation =
            typeof (notation) != "undefined" && notation !== null ? notation : null;
        if (this.notation === null) {
            switch (effect) {
                case "blow":
                    this.notation = "" + hole;
                    break;
                case "draw":
                    this.notation = `(${hole})`;
                    break;
                case "draw-bend":
                    this.notation = `(${hole})'`;
                    break;
                case "blow-bend":
                    this.notation = `${hole}'`;
                    break;
                case "overblow":
                    this.notation = `${hole}o`;
                    break;
                case "overdraw":
                    this.notation = `(${hole})o`;
                    break;
            }
        }
        this.difficulty =
            typeof (difficulty) != "undefined" && difficulty !== null ? difficulty : DEFAULT_DIFFICULTIES.get(effect);
    }
}

export const RICHTER_HARP_NOTES = [
    new RichterHarpNote(0, 'blow', 1),
    new RichterHarpNote(1, 'draw-bend', 1),
    new RichterHarpNote(2, 'draw', 1),
    new RichterHarpNote(3, 'overblow', 1),
    new RichterHarpNote(4, 'blow', 2),
    new RichterHarpNote(5, 'draw-bend', 2, "(2)''"),
    new RichterHarpNote(6, 'draw-bend', 2),
    //new RichterHarpNote(7, 'blow'),
    new RichterHarpNote(7, 'draw', 2),
    new RichterHarpNote(8, 'draw-bend', 3, "(3)'''"),
    new RichterHarpNote(9, 'draw-bend', 3, "(3)''"),
    new RichterHarpNote(10, 'draw-bend', 3),
    new RichterHarpNote(11, 'draw', 3),
    new RichterHarpNote(12, 'blow', 4),
    new RichterHarpNote(13, 'draw-bend', 4),
    new RichterHarpNote(14, 'draw', 4),
    new RichterHarpNote(15, 'overblow', 4),
    new RichterHarpNote(16, 'blow', 5),
    new RichterHarpNote(17, 'draw', 5),
    new RichterHarpNote(18, 'overblow', 5),
    new RichterHarpNote(19, 'blow', 6),
    new RichterHarpNote(20, 'draw-bend', 6),
    new RichterHarpNote(21, 'draw', 6),
    new RichterHarpNote(22, 'overblow', 6),
    new RichterHarpNote(23, 'draw', 7),
    new RichterHarpNote(24, 'blow', 7),
    new RichterHarpNote(25, 'overdraw', 7),
    new RichterHarpNote(26, 'draw', 8),
    new RichterHarpNote(27, 'blow-bend', 8),
    new RichterHarpNote(28, 'blow', 8),
    new RichterHarpNote(29, 'draw', 9),
    new RichterHarpNote(30, 'blow-bend', 9),
    new RichterHarpNote(31, 'blow', 9),
    new RichterHarpNote(32, 'overdraw', 9),
    new RichterHarpNote(33, 'draw', 10),
    new RichterHarpNote(34, 'blow-bend', 10, "10''"),
    new RichterHarpNote(35, 'blow-bend', 10),
    new RichterHarpNote(36, 'blow', 10),
    new RichterHarpNote(37, 'overdraw', 10)
]

export const RICHTER_HARP_MIN_SHIFT = 0; //C3
export const RICHTER_HARP_MAX_SHIFT = 17; //F4

export function notation(richterHarpNotes) {
    //console.log(richterHarpNotes)
    return richterHarpNotes.reduce((result, currentItem) => {
        return result + currentItem.notation + " ";
    }, "")
}