//import React, {useContext} from 'react'
import React from 'react'
import PropTypes from 'prop-types'
import NotesContext from "../notesContext";


class NoteComponent extends React.Component {
    render() {
        const {notes} = this.context;
        // console.log(notes);
        // console.log(notes[0]);
        const note = notes[this.props.ordinal + this.props.shift];
        return (
            <div className="note">
            <table>
                <tbody>
                <tr>
                    <td rowSpan="2" className='tone'>{note.tone}</td>
                    <td className="index octave">{note.octave}</td>
                </tr>
                <tr>
                    <td className="index flatsharp">{note.flatsharp === "" ? '\u00A0' : note.flatsharp}</td>
                </tr>
                </tbody>
            </table>
        </div>
        );
    }
}
NoteComponent.contextType = NotesContext;
NoteComponent.propTypes = {
    ordinal: PropTypes.number.isRequired,
    shift: PropTypes.number.isRequired
};

export default NoteComponent