import React from 'react'
import Note from './Note'

export const NOTES = [
    new Note('C', 3, ''), new Note('C', 3, '#'), new Note('D', 3, ''), new Note('E', 3, 'b'), new Note('E', 3, ''), new Note('F', 3, ''), new Note('F', 3, '#'), new Note('G', 3, ''), new Note('G', 3, '#'), new Note('A', 3, ''), new Note('B', 3, 'b'), new Note('B', 3, ''),
    new Note('C', 4, ''), new Note('C', 4, '#'), new Note('D', 4, ''), new Note('E', 4, 'b'), new Note('E', 4, ''), new Note('F', 4, ''), new Note('F', 4, '#'), new Note('G', 4, ''), new Note('G', 4, '#'), new Note('A', 4, ''), new Note('B', 4, 'b'), new Note('B', 4, ''),
    new Note('C', 5, ''), new Note('C', 5, '#'), new Note('D', 5, ''), new Note('E', 5, 'b'), new Note('E', 5, ''), new Note('F', 5, ''), new Note('F', 5, '#'), new Note('G', 5, ''), new Note('G', 5, '#'), new Note('A', 5, ''), new Note('B', 5, 'b'), new Note('B', 5, ''),
    new Note('C', 6, ''), new Note('C', 6, '#'), new Note('D', 6, ''), new Note('E', 6, 'b'), new Note('E', 6, ''), new Note('F', 6, ''), new Note('F', 6, '#'), new Note('G', 6, ''), new Note('G', 6, '#'), new Note('A', 6, ''), new Note('B', 6, 'b'), new Note('B', 6, ''),
    new Note('C', 7, ''), new Note('C', 7, '#'), new Note('D', 7, ''), new Note('E', 7, 'b'), new Note('E', 7, ''), new Note('F', 7, ''), new Note('F', 7, '#'), new Note('G', 7, ''), new Note('G', 7, '#'), new Note('A', 7, ''), new Note('B', 7, 'b'), new Note('B', 7, ''),
    new Note('C', 8, ''), new Note('C', 8, '#'), new Note('D', 8, ''), new Note('E', 8, 'b'), new Note('E', 8, ''), new Note('F', 8, ''), new Note('F', 8, '#'), new Note('G', 8, ''), new Note('G', 8, '#'), new Note('A', 8, ''), new Note('B', 8, 'b'), new Note('B', 8, '')
];

const NotesContext = React.createContext({notes: NOTES});
export default NotesContext;